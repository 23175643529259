import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Plugins } from '@capacitor/core';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { AuthService } from '../services/auth.service';
const { Storage } = Plugins;

export const INTRO_KEY = 'intro-seen';

@Injectable({
  providedIn: 'root'
})
export class IntroGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.isLoggedIn();
  }

  isLoggedIn(): Promise<boolean> {
    return this.authService.getUser().then((user: User) => {
      const isAuthenticated = user != null;

      if (!isAuthenticated) {
        return true;
      }
      else {
       // this.router.navigateByUrl('', { replaceUrl: true });
        return true;
      }
    });
  }

  // canLoad() {

  //   const isAuthenticated = this.authService.getUser() != null;

  //   if (!isAuthenticated) {
  //     return true;
  //   }
  //   else {
  //     this.router.navigateByUrl('/tabs', { replaceUrl: true });
  //     return false;
  //   }
  // }
}