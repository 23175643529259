import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Success } from '../models/success.model';
import { Token } from '../models/token.model';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { EventService } from './event.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private storage: StorageService,
    private apiService: ApiService,
    private eventService: EventService) {
    this.getUserInfo();
  }

  USER_STORAGE_KEY = 'userInfo';
  private user: User = undefined;
  public userChanged: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);

  setUser(user: User): void {
    this.user = user;
    this.userChanged.next(this.user);
  }

  async getUser(): Promise<User> {
    if (this.user === undefined) {
      await this.getUserInfo();
    }
    return this.user;
  }

  async getUserInfo(): Promise<User> {
    const userInfo = await this.storage.getData(this.USER_STORAGE_KEY);
    if (userInfo) {
      const user = JSON.parse(userInfo);
      this.setUser(user);
      return JSON.parse(userInfo);
    }
    this.setUser(null);
    return null;
  }

  setUserInfo(user: User) {
    this.setUser(user);
    this.storage.setData(this.USER_STORAGE_KEY, JSON.stringify(user));
  }

  removeAndLogoutUser() {
    this.setUser(undefined);
    this.storage.remove(this.USER_STORAGE_KEY);
    this.eventService.publish('user:logout', null);
  }

  login(phone, fcmToken) {
    return this.apiService.userLogin({ phone: phone, fcmToken: fcmToken }).pipe(switchMap((success: Success) => {
      return of(success);
    }),
      catchError((err) => {
        console.log('login failed : ', err);
        this.removeAndLogoutUser();
        return throwError(err);
      }));
  }

  signUp(phone, fcmToken) {
    return this.apiService.userSignUp({ phone: phone, fcmToken: fcmToken }).pipe(switchMap((success: Success) => {
      if (success.success) {
        return of(success);
      }
      return of(success);
    }),
      catchError((err) => {
        console.log('signUp failed : ', err);
        return throwError(err);
      }));
  }

  verifyOtp(phone, otp) {
    return this.apiService.verifyOtp({ 'phone': phone, 'role': 'user', 'otp': otp }).pipe(switchMap((user: User) => {
      if (user.accessToken && user.refreshToken) {
        this.setUserInfo(user);
      }
      return of(user);
    }), 
    catchError((err) => {
      console.log('verifyOtp failed : ', err);
      return throwError(err);
    }));
  }

  refreshTokens(): Observable<User> {
    return from(this.getUserInfo()).pipe(switchMap((user: User) => {
      if (!user) {
        this.removeAndLogoutUser();
        return throwError('user empty');
      }
      return this.apiService.refreshToken({ refreshToken: user.refreshToken }).pipe(
        switchMap((refreshToken: Token) => {
          user.accessToken = refreshToken.accessToken;
          user.refreshToken = refreshToken.refreshToken;
          console.log('tokens refreshed successfully');
          this.setUserInfo(user);
          return of(user);
        }),
        catchError((err) => {
          console.log('refreshing tokens : ', err);
          this.removeAndLogoutUser();
          return throwError(err);
        }));
    }));
  }

}
