
import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, NavigationEnd, UrlSegment, UrlTree, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.isLoggedIn(route.data.returnTo);
  }

  isLoggedIn(returnTo: string): Promise<boolean> {
    return this.authService.getUser().then((user: User) => {
      const isAuthenticated = user != null;
      if (isAuthenticated) {
        return true;
      }
      else {
        this.router.navigate(['login'], { replaceUrl: true, queryParams: {'returnTo': returnTo}});
        return false;
      }
    });

  }

  // async canLoad() {
  //   const user = this.authService.getUser();
  //   const isAuthenticated = user != null;

  //   if (isAuthenticated) {
  //     return true;
  //   }
  //   else {
  //     this.router.navigateByUrl('/', { replaceUrl: true });
  //     return false;
  //   }
  // }
}