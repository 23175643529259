import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroGuard } from './guards/intro.guard';

const routes: Routes = [
  { path: '', redirectTo: '/shops', pathMatch: 'full' },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [IntroGuard],
  //  canLoad:[AuthGuard]
  },  
  {
    path: 'shops',
    loadChildren: () => import('./pages/shops/shops.module').then(m => m.ShopsPageModule),
    canActivate: [IntroGuard]
  },
  {
    path: 'tab4',
    loadChildren: () => import('./profile-Tab/profile-Tab.module').then(m => m.Tab4PageModule),
    canActivate: [IntroGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [IntroGuard],
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule),
    canActivate: [IntroGuard]
  },
  {
    path: 'otp',
    loadChildren: () => import('./pages/otp/otp.module').then(m => m.OtpPageModule),
    canActivate: [IntroGuard]
  },
  {
    path: 'editprofile',
    loadChildren: () => import('./pages/editprofile/editprofile.module').then(m => m.EditprofilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'password',
    loadChildren: () => import('./pages/password/password.module').then(m => m.PasswordPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'address',
    loadChildren: () => import('./pages/address/address.module').then(m => m.AddressPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'address/newaddress',
    loadChildren: () => import('./pages/address/newaddress/newaddress.module').then(m => m.NewAddressPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'myorders',
    loadChildren: () => import('./ordersTab/my-orders/order-list.module').then(m => m.OrderListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'orders',
    loadChildren: () => import('./ordersTab/my-orders/order-list.module').then(m => m.OrderListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'myorders/orderdetail',
    loadChildren: () => import('./ordersTab/order-detail/order-detail.module').then(m => m.OrderDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'search/:shopId/:searchTerm',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule),
        canActivate: [IntroGuard,AuthGuard]
      }
    ]
   
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'errorpage',
    loadChildren: () => import('./pages/errorpage/errorpage.module').then(m => m.ErrorpagePageModule)
  },
  {
    path: 'detail/:id',
    children: [
      {
        path: '',
        loadChildren: () => import('./fav-Tab/detail/detail.module').then(m => m.DetailPageModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'track',
    children: [
      {
        path: ':id',
        loadChildren: () => import('./ordersTab/order-tracker/order-tracker.module').then( m => m.OrderTrackerPageModule),
      },
    ]
  },
  { path: ':shopName',
    loadChildren: () => import('./pages/shops/shops.module').then(m => m.ShopsPageModule),
    canActivate: [IntroGuard]
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
