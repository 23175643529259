import { Injectable } from '@angular/core'
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse
} from '@angular/common/http';
import { EventService } from './event.service';
import { from, Observable, throwError } from 'rxjs';

import { AuthService } from './auth.service';
import { User } from '../models/user.model';
import { mergeMap, timeout, catchError, switchMap } from 'rxjs/operators';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  public static UNAUTHORIZED: number = 401; // Status, wenn Request allgemein nicht akzeptiert wurde
  public static PRECONDITION_FAILED: number = 412; // Status, wenn JWT-Token abgelaufen

  constructor(
    public authService: AuthService,
    public eventService: EventService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.authService.getUserInfo()).pipe(mergeMap((user: User) => {
      request = this.setToken(request, user);
      return next.handle(request).pipe(timeout(60000),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === TokenInterceptor.PRECONDITION_FAILED) {
              return this.authService.refreshTokens().pipe(switchMap((user) => {
                request = this.setToken(request, user);
                return next.handle(request);
              }));
            }
            else if (error.status === TokenInterceptor.UNAUTHORIZED) {
              this.eventService.publish('user:logout', null);
            }
            else {
              console.log('Fehler in Interceptor: ', error.status, ', ', error.statusText);
              return throwError(error);
            }
          }
          return throwError(error);
        }));
    }));
  }

  setToken(request: HttpRequest<unknown>, user: User) {
    return request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user == null ? 'NOTOKEN' : user.accessToken}`,
      }
    });
  }
}