import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Category } from '../models/category.model';
import { Product } from '../models/product.model';
import { Shop } from '../models/shop.model';
import { SubCategory } from '../models/subCategory.model';
import { Token } from '../models/token.model';
import { User } from '../models/user.model';
import { Order } from '../models/order.model';
import { Otp } from '../models/otp.model';
import { Address } from '../models/address.model';
import { Feedback } from '../models/feedback.model';
import { Success } from '../models/success.model';
import { Offer } from '../models/offer.model';
import { Restriction } from '../models/restriction.model';

export interface ProductsQueryResponse {
  products: Product[],
  totalCount: number
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  API_URL = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public userSignUp(model: any) {
    return this.http.post<Success>(this.API_URL + 'signup', model, { observe: 'body' });
  }

  public userLogin(model: any) {
    return this.http.post<Success>(this.API_URL + 'login', model, { observe: 'body' });
  }
  public verifyOtp(model: any) {
    return this.http.post<User>(this.API_URL + 'validate_otp', model, { observe: 'body' });
  }
  public resendOtp(model: any) {
    return this.http.post<Otp>(this.API_URL + 'resend_otp', model, { observe: 'body' });
  }

  public refreshToken(model: any): Observable<Token> {
    return this.http.post<Token>(this.API_URL + 'refresh_tokens', model, { observe: 'body' });
  }

  public getCategories(model: any): Observable<Category[]> {
    return this.http.post<Category[]>(this.API_URL + 'get_categories', model, { observe: 'body' });
  }

  public getSubCategories(model: any): Observable<SubCategory[]> {
    return this.http.post<SubCategory[]>(this.API_URL + 'get_sub_categories', model, { observe: 'body' });
  }

  public getProducts(model: any): Observable<Product[]> {
    return this.http.post<Product[]>(this.API_URL + 'get_products', model, { observe: 'body' }).pipe(share());
  }

  public searchProductSubCategories(shopId: number, search:string): Observable<SubCategory[]> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('shopId', shopId);
    searchParams = searchParams.append('search', search);
    searchParams = searchParams.append('facetBy', "subCategory");
    return this.http.get<SubCategory[]>(this.API_URL + 'products', { params: searchParams });
  }

  public searchProducts(shopId: number, limit:number, offset: number, sortBy: string, sortDir: string, search: string =''): Observable<ProductsQueryResponse> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('shopId', shopId);
    searchParams = searchParams.append('limit', limit);
    searchParams = searchParams.append('offset', offset);
    searchParams = searchParams.append('sortBy', sortBy);
    searchParams = searchParams.append('sortDir', sortDir);
    if(search.length > 0) {
      searchParams = searchParams.append('search', search);
    }
    return this.http.get<ProductsQueryResponse>(this.API_URL + 'products', { params: searchParams });
  }

  public searchProductsInSubCategory(shopId: number, limit:number, offset: number, sortBy: string, sortDir: string, subCategoryId: number, search: string =''): Observable<ProductsQueryResponse> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('shopId', shopId);
    searchParams = searchParams.append('limit', limit);
    searchParams = searchParams.append('offset', offset);
    searchParams = searchParams.append('sortBy', sortBy);
    searchParams = searchParams.append('sortDir', sortDir);
    searchParams = searchParams.append('subCategoryId', subCategoryId);
    if(search.length > 0) {
      searchParams = searchParams.append('search', search);
    }
    return this.http.get<ProductsQueryResponse>(this.API_URL + 'products', { params: searchParams });
  }

  public getOrders(model: any): Observable<Order[]> {
    return this.http.post<Order[]>(this.API_URL + 'get_orders', model, { observe: 'body' }).pipe(share());
  }

  public getShops(): Observable<Shop[]> {
    return this.http.post<Shop[]>(this.API_URL + 'get_shops', { observe: 'body' });
  }

  public saveOrder(model: any) {
    return this.http.post<Order>(this.API_URL + 'save_order', model, { observe: 'body' });
  }

  public checkTransaction(model: any) {
    return this.http.post<Order>(this.API_URL + 'check_transaction', model, { observe: 'body' });
  }

  public getAddresses(model: any): Observable<Address[]> {
    return this.http.post<Address[]>(this.API_URL + 'get_addresses', model, { observe: 'body' });
  }

  public saveFeedback(model: any) {
    return this.http.post<Feedback>(this.API_URL + 'add_feedback', model, { observe: 'body' });
  }
  public saveAddress(model: any): Observable<Address[]> {
    return this.http.post<Address[]>(this.API_URL + 'save_addresses', model, { observe: 'body' });
  }

  public getOrder(orderId: number): Observable<Order> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('orderId', orderId);
    return this.http.get<Order>(this.API_URL + 'orders', { params: searchParams });
  }

  public getTrackerOrder(trackerId: string): Observable<Order> {
    return this.http.post<Order>(this.API_URL + 'get_tracker_order', { trackerId: trackerId }, { observe: 'body' });
  }

  public setOrderRating(orderId: number, rating: number): Observable<Order> {
    return this.http.post<Order>(this.API_URL + 'set_tracker_rating', { orderId: orderId, rating: rating }, { observe: 'body' });
  }

  public setOrderFeedback(orderId: number, feedback: string): Observable<Order> {
    return this.http.post<Order>(this.API_URL + 'set_tracker_feedback', { orderId: orderId, feedback: feedback }, { observe: 'body' });
  }

  public getOffers(shopId: number): Observable<Offer[]> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('shopId', shopId);
    return this.http.get<Offer[]>(this.API_URL + 'offers', { params: searchParams });
  }

  public getRestrictions(shopId: number): Observable<Restriction[]> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('shopId', shopId);
    return this.http.get<Restriction[]>(this.API_URL + 'restrictions', { params: searchParams });
  }

  public event(data: any): Observable<any> {
    return this.http.post(this.API_URL + 'event', data, { observe: 'body' });
  }

}
