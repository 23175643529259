import { Component } from '@angular/core';
//import { FCM } from '@awesome-cordova-plugins/fcm/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic';
import { Platform } from '@ionic/angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    //private fcm:FCM,
    private platform: Platform,
    ) {
    //this.initializeApp()
  }
  getFCMToken(){
    FCM.getToken().then(token => {
      return token;
    });
  }
  initializeApp(){
    this.platform.ready().then(() => {
    FCM.getToken().then(token => {
    });
    // ionic push notification example
    FCM.onNotification().subscribe(data => {
      if (data.wasTapped) {
        console.log('Received in background');
      } else {
        console.log('Received in foreground');
      }
    });      
    // refresh the FCM token
    FCM.onTokenRefresh().subscribe(token => {
      console.log(token);
    });
    // unsubscribe from a topic
    // FCM.unsubscribeFromTopic('offers');
  });
  }

   
}
