import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  async setData(key: string, value: string): Promise<void> {
    return await Storage.set({ key: key, value: value });
  }

  async getData(key: string): Promise<string | null> {
    const { value } = await Storage.get({ key: key });
    return value;
  }

  async remove(key: string): Promise<void> {
    return await Storage.remove({key: key});
  }
}
